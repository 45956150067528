
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {queryTourSpecsListApi, queryTourSpecsValueListApi} from "@/apis/travelManage";
import {Message} from "element-ui";

/**
 * @name: specification
 * @author: Gzm
 * @date: 2023-05-30 15:21
 * @description：规格库存组件
 * @update: 2023-05-30 15:21
 */
@Component({})
export default class UserIndex extends Vue {
  @Prop([]) data!: any;
  form: any = {
    tourSkuPrice: null,
    tourNumber: null
  }
  // 旅游sku列表
  tourSkuDtoList: any = [{
    tourTime: '',
    tourSkuPrice: null,
    tourNumber: null
  }]
  // 日期
  dateList: any = [""]
  // 月份
  month: any = ""
  // 规格名称下拉
  tourSpecsValueList: any = []
  // 规格名称下拉值
  selectValue: string = ""
  // 规格列表
  specificationList: string[] = []
  // 规格值
  specification: string = ""
  // 规格索引
  specificationBoxIndex: number = 0
  // 所有日历数据
  allCalendarData: any[] = []

  /**
   * 触发输入框
   * @param e
   */
  handelFocus(e: any) {
    if (this.allCalendarData[this.specificationBoxIndex].dateList.indexOf(e) >= 0) {
      return
    } else {
      let spData: any = {}
      spData[`${this.selectValue.split('-')[1]}`] = this.specificationList[this.specificationBoxIndex]
      this.allCalendarData[this.specificationBoxIndex].tourSkuDtoList.push({
        tourTime: e,
        tourSkuPrice: null,
        tourNumber: null,
        skuName: this.specificationList[this.specificationBoxIndex],
        spData: JSON.stringify(spData)
      })
      this.allCalendarData[this.specificationBoxIndex].dateList.push(e)
    }
  }

  getDayData(e: any) {
    if (this.allCalendarData[this.specificationBoxIndex].dateList.indexOf(e) >= 0) {
      return this.allCalendarData[this.specificationBoxIndex].dateList.indexOf(e)
    } else {
      return 0
    }
  }

  getTourSpecsValueList() {
    queryTourSpecsValueListApi().then(e => {
      this.tourSpecsValueList = e.map((item: any) => {
        item.value = `${item.id}-${item.specsName}`
        return item
      })

    })
  }

  /**
   * 列出规格
   */
  handleSpecification() {
    if (!this.month) {
      return Message.error('请选择月份')
    }
    this.specificationBoxIndex = 0
    if (this.selectValue.split('-')[0]) {
      queryTourSpecsListApi(this.selectValue.split('-')[0]).then(e => {
        this.allCalendarData = []
        this.specificationList = e.map((item: any) => {
          this.allCalendarData.push({
            tourSkuDtoList: [{
              tourNumber: null,
              tourSkuPrice: null
            }],
            dateList: [""]
          })
          return item.name
        })
        console.log( this.allCalendarData,' this.allCalendarData this.allCalendarData this.allCalendarData this.allCalendarData')
        this.handelBlur()
      })

    } else {
      Message.error('请先选择规格')
    }
  }

  getDate(time: any) {
    let newTime = time.getFullYear() + '-' + (time.getMonth() + 1).toString().padStart(2, "0")
    return newTime
  }

  /**
   * 删除规格
   */
  iconRemove(index: number) {
    this.specificationList.splice(index, 1)
    this.allCalendarData.splice(index, 1)
    this.specificationBoxIndex = 0
    this.handelBlur()
  }

  /**
   * 新增规格
   */
  addSpecificationList() {
    if (this.specificationList.indexOf(this.specification) >= 0) {
      return Message.error('已有该规格，请勿重复新增')
    }
    if (this.specification) {
      this.specificationList.push(this.specification)
      this.allCalendarData.push({
        tourSkuDtoList: [{
          tourNumber: null,
          tourSkuPrice: null
        }],
        dateList: [""]
      })
    } else {
      Message.error('请输入规格名称')
    }
  }

  /**
   * 点击规格
   */
  handleSpecificationBox(index: number) {
    this.specificationBoxIndex = index
  }

  /**
   * 上下一步
   * @param number
   *
   */
  handleNext(tab: string) {
    this.$emit('Next', tab)
  }

  numberInputTourSkuPrice(val: any, index: any) {
    if (val == '') {
      return
    }
    let re = /([0-9]+.[0-9]{2})[0-9]*/;
    let value = (val.replace(re, "$1"));
    if (val <= 0) {
      this.allCalendarData[this.specificationBoxIndex].tourSkuDtoList[index].tourSkuPrice = 0.01
      Message.error('价格不能小于等于0')
    } else {
      this.allCalendarData[this.specificationBoxIndex].tourSkuDtoList[index].tourSkuPrice = value
    }
  }

  numberInputTourNumber(val: any, index: any) {
    if (val == '') {
      return
    }
    let value = val.replace(/[^\d]/g, '')
    if (val < 0) {
      this.allCalendarData[this.specificationBoxIndex].tourSkuDtoList[index].tourNumber = 1
      Message.error('不能小于0')
    } else {
      this.allCalendarData[this.specificationBoxIndex].tourSkuDtoList[index].tourNumber = value
    }
  }

  handelBlur() {
    console.log(this.allCalendarData,'cahng')
    this.$emit('SpecsAllData', this.allCalendarData)
  }

  get computedData() {
    return this.data;
  }

  @Watch('computedData')
  async onChangeValue(newVal: any, oldVal: any) {
    this.month = new Date(newVal[0].tourTime.split('-').slice(0, 2).join('-'))
    let e = await queryTourSpecsValueListApi()
    for (let i in JSON.parse(newVal[0].spData)) {
      e.forEach((item: any) => {
        if (item.specsName == i) {
          this.selectValue = `${item.id}-${item.specsName}`
        }
      })
    }
    queryTourSpecsListApi(this.selectValue.split('-')[0]).then(e => {
      this.allCalendarData = []
      this.specificationList = e.map((item: any) => {
        this.allCalendarData.push({
          tourSkuDtoList: [{
            tourNumber: null,
            tourSkuPrice: null
          }],
          dateList: [""]
        })
        return item.name
      })

      let skuNameList:any = []
      newVal.forEach((item:any) => {
        skuNameList.push(item.skuName)
      })

      Array.from(new Set(skuNameList)).forEach((item:any) => {
        if (this.specificationList.indexOf(item) == -1){
          this.allCalendarData.push({
            tourSkuDtoList: [{
              tourNumber: null,
              tourSkuPrice: null
            }],
            dateList: [""]
          })
          this.specificationList.push(item)
        }
      })

      newVal.forEach((item:any) => {
        let index =  this.specificationList.indexOf(item.skuName)
        this.allCalendarData[index].tourSkuDtoList.push(item)
        this.allCalendarData[index].dateList.push(item.tourTime)
      })
      this.$emit('SpecsAllData', this.allCalendarData)

    })
  }

  created() {
    this.getTourSpecsValueList();
  }
}

